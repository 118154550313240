export const newsList = [
  {
    id: 319,
    src: require('../../assets/images/mainPageImages/newsImages/news-319.jpg'),
    title: 'Мастер - класс',
    description: 'Татьяны Гарифуллиной прошёл в ДК "Юбилейный" 1 октября. Вместе с Татьяной Николаевной ребята изготовили из цветной бумаги необычные плетёные корзиночки - подарки для любимых бабушек. Смотрите, что получилось!'
  },
  {
    id: 318,
    src: require('../../assets/images/mainPageImages/newsImages/news-318.jpg'),
    title: 'Уроки шитья',
    description: 'Стежок: "НАЗАД ИГОЛКУ" освоили участницы мастер-класса Л. М. Матвеевой 26 сентября в ДК "Звёздный" прошёл мастер-класс по шитью ко Дню старшего поколения под названием: "Милая, родная - бабушка моя!" Ведь кто, если ни наши бабули, знают всё о рукоделии? Руководитель студии "Ателье для кукол" Лариса Матвеева рассказала детям, какие бывают швы и показала технику выполнения некоторых их них. А вы умеете шить, вышивать или вязать? Ставьте, если ДА! Ставьте, если КОНЕЧНО!'
  },
  {
    id: 317,
    src: require('../../assets/images/mainPageImages/newsImages/news-317.jpg'),
    title: 'Концерт для военнослужащих',
    description: 'Сегодня, 24 сентября, вместе с участниками художественной самодеятельности Дома культуры "Звездный" поздравили служащих воинской части с днем ее основания. В концертной программе были представлены лучшие творческие номера: вокальные и хореографические. Артистов военнослужащие встречали и провожали бурными овациями. Мне очень приятно, что творчество наших краснопахорцев отзывается неподдельной искренностью в сердцах людей, а тем более защитников нашей Родины!'
  },
  {
    id: 316,
    src: require('../../assets/images/mainPageImages/newsImages/news-316.jpg'),
    title: 'Беседа',
    description: 'В доме культуры «Юбилейный» состоялась беседа, посвященная Дню солидарности в борьбе с терроризмом.Этот день приурочен к трагическим событиям, произошедшим 1-3 сентября 2004 года в городе Беслан (Северная Осетия), когда в результате беспрецедентного по своей жестокости террористического акта погибли более 300 человек, в основном женщины и дети. 3 сентября Россия отдает дань памяти тысячам соотечественников, погибшим от рук террористов'
  },
  {
    id: 315,
    src: require('../../assets/images/mainPageImages/newsImages/news-315.jpg'),
    title: 'Собери ребенка в школу',
    description: 'Всероссийскую акцию «Собери ребенка в школу» провели в Краснопахорском. 27 ребят получили портфели и школьные принадлежности к началу учебного года. Первоклашек и школьников поздравили глава Управы Краснопахорского района Юрий Няньчур, председатель Совета ветеранов Лидия Безрукова, директор ДК «Звездный» Надежда Матвеева'
  },
  {
    id: 314,
    src: require('../../assets/images/mainPageImages/newsImages/news-314.jpg'),
    title: 'Беседа',
    description: 'Заместитель директора дома культуры «Звездный» Елена Михайлина провела беседу с воспитанниками клубных формирований.Тема разговора: «Молодежные субкультуры: «за» и «против». Ребята отметили, что инстересуются субкультурами, но больше времени все же уделяют саморазвитию, как в школе, так и в доме культуры.'
  },

,

]

export const moscowEvents = [
    {
      id: 2,
      type: 'Интерактивная, Классическое искусство',
      name: 'Айвазовский. Кандинский. Живые полотна',
      place: 'Пространство «Люмьер-холл»',
      date: 'до 23 апреля 2023',
      description: 'На выставке представлено более 100 работ Айвазовского из музеев по всей России, большинство из них сделано в формате ps-3D, что позволяет посетителям почувствовать себя внутри картины. А мощные проекторы высокого разрешения дополненные объемным звуком перенесут посетителей за тысячи километров в акваторию Черного моря на место известных корабельных сражений.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-2.jpg'),
      to: 'https://www.afisha.ru/exhibition/258674/'
    },
    {
      id: 3,
      type: 'Выставка в Москве',
      name: 'Первые моторы России',
      place: 'Музей гаража особого назначения ФСО России',
      date: 'до 27 октября 2023',
      description: 'О первых страницах истории русского автомобилизма — зарождении технической культуры, творчества, инженерной школы — расскажет выставка «Первые моторы России» в Музее Гаража особого назначения на ВДНХ.Павильон 54 музея превращен в зал IV Международной автомобильной выставки. В исторических интерьерах представлены уникальные экспонаты из коллекции Политехнического музея, находившиеся в запасниках и впервые собранные вместе.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-3.jpeg'),
      to: 'https://www.afisha.ru/exhibition/272590/'
    },
    {
      id: 4,
      type: 'ВЫСТАВКА В МОСКВЕ',
      name: 'Космонавтика и авиация',
      place: 'Павильон «Космос»',
      date: 'Постоянная экспозиция',
      description: 'В основной экспозиции — огромный архив документальных свидетельств покорения космоса: 120 образцов одной только техники и больше 2 тысяч разных документов, включая фото и видео. Под куполом расположен 5D-кинотеатр, который рассказывает о Большом взрыве и светлом будущем с колонией на Марсе.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-4.jpg'),
      to: 'https://www.afisha.ru/exhibition/208082/'
    }
]