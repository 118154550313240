export const eventsConfig = [
    {
        id: 10,
        eventDay: '27',
        eventDayNumber: 'ВС',
        eventInfoDate: '27 октября',
        eventInfoTitle: 'Венок дружбы',
        eventDescription: 'Мы готовим для вас 10 межрегиональный фестиваль, посвященный Международному дню толерантности 27 октября в 12-00ч.',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/venok-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/venok-02.jpg'),
    },
    {
        id: 9,
        eventDay: '20',
        eventDayNumber: 'ВС',
        eventInfoDate: '20 октября',
        eventInfoTitle: 'Конкурсно-игровая программа',
        eventDescription: 'Мы готовим для вас 20 октября в 12-00 конкурсно-игровую программу под названием "Лучший папа" приводите пап и участвуйте сами',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/papaa-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/papaa-02.jpg'),
    },
    {
        id: 8,
        eventDay: '12',
        eventDayNumber: 'СБ',
        eventInfoDate: '12 октября',
        eventInfoTitle: 'Фестиваль-конкурс',
        eventDescription: 'Дом культуры «Звездный» вновь приглашает любителей поэзии стать участниками литературного праздника Фестиваль-конкурс состоится 12 октября в 12:00 в ДК "Звездный".Обращаем ваше внимание, что приём заявок осуществляется до 1 октября! Материал, не отвечающий тематике фестиваля, допущен не будет!',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/ozen-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/ozen-02.jpg'),
    },
    {
        id: 7,
        eventDay: '05',
        eventDayNumber: 'СБ',
        eventInfoDate: '05 октября',
        eventInfoTitle: 'Концерт',
        eventDescription: '05 октября приглашаем на концерт ко Дню пожилого человека, так же будет чаепитие и душевные разговоры ',
        eventCost: 'Библиотека Минзаг',
        eventImgSrc: require('../../assets/images/eventsImages/minzag-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/minzag-02.jpg'),
    },
    {
        id: 5,
        eventDay: '04',
        eventDayNumber: 'ПТ',
        eventInfoDate: '04 октября',
        eventInfoTitle: 'Концертная программа',
        eventDescription: '03 октября Приглашаем в 17-00 на встречу в клубе "Сельчанка" а в 18-00 состоится премьера спектакля "Деревенский детектив" театрального коллектива "Арлекин+" ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/star-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/star-02.jpg'),
    },
    {
        id: 4,
        eventDay: '03',
        eventDayNumber: 'ЧТ',
        eventInfoDate: '03 октября',
        eventInfoTitle: 'Тематическая встреча и спектакль',
        eventDescription: '03 октября Приглашаем в 17-00 на встречу в клубе "Сельчанка" а в 18-00 состоится премьера спектакля "Деревенский детектив" театрального коллектива "Арлекин+" ',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/selchanka-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/selchanka-02.jpg'),
    },
    {
        id: 3,
        eventDay: '01',
        eventDayNumber: 'ВТ',
        eventInfoDate: '01 октября',
        eventInfoTitle: 'Мастер-класс',
        eventDescription: '01 сентября приглашаем на мастер-класс в дом культуры "Юбилейный" Татьяна Гарифуллина, руководитель студии декоративно-прикладного творчества "Клубочек", приглашает на мастер-класс "Подарок для любимой бабушки" ',
        eventCost: 'ДК Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/podarok-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/podarok-02.jpg'),
    },
    {
        id: 2,
        eventDay: '04',
        eventDayNumber: 'ПН',
        eventInfoDate: '04-27 октября',
        eventInfoTitle: 'Расписание мероприятий ДК «Звездный»',
        eventDescription: 'С 04-27 октября мероприятия в Домах культуры «Звездный» и «Юбилейный» ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/oktKP-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/oktKP-02.jpg'),
    },
    {
        id: 1,
        eventDay: '01',
        eventDayNumber: 'ВТ',
        eventInfoDate: '01-25 октября',
        eventInfoTitle: 'Расписание мероприятий ДК «Юбилейный»',
        eventDescription: 'С 01-25 октября мероприятия в Домах культуры «Звездный» и «Юбилейный» ',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/oktB-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/oktB-02.jpg'),
    },
]